<template>
	<div>
		<maincontent :maincontentdata="contactdata"></maincontent>
	</div>
</template>

<script>
	import maincontent from  '@/components/common_components/miancontent.vue'

	export default {
		components: {
			maincontent
		},
		data() {
			return {
				contactdata:[]
			}
		},
		created() {
			this.$post("/industry/categoryall",{category:"联系我们"}).then(resp=>{
				this.contactdata = resp.dataList
			})
		}
	}
</script>

<style src="@/assets/css/cont.css" scoped>
</style>
